* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  font-family: 'Roboto Condensed', sans-serif;
  width: 100vw;
  height: 170vh;
  background: linear-gradient(to bottom, rgb(255 255 255),rgb(135 122 151 / 99%));
}

header {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 80px 0 49px 0;
  display: flex;
  justify-content: center;
  align-items: center;
 }

 h1 {
  color: #ffffff;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: #736b89;
  text-shadow: 10px 0px #cecae3, 25px 0px 5px #b9b1cb87;
  font-size: 60px;
  text-align: center;
  letter-spacing: 1rem;
 }

.inputForm {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputForm input {
  width: 24rem;
  height: 2.9rem;
  padding: 1rem;
  border: none;
}

.inputForm button {
  padding: 1rem 1rem;
  background: rgba(144, 128, 160, 0.62);

}

.todoList {
  width: 100vw;
  margin: 2rem auto;
}

.todo {
  margin: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.completed {
  text-decoration: line-through;
  opacity: 0.5;
}


.icons .fa-check {
  padding: 0.487rem .5rem;
  background: rgb(197, 189, 202);
}

.icons .fa-trash {
  padding: 0.487rem .5rem;
  background: rgb(163 163 163);
}

.todoText {
  min-width: 15rem;
  max-height: 1.8rem;
  padding: 0.3rem 4rem;
  background: white;
}


button {
  border: none;
}

/* レスポンシブ */
@media screen and (max-width: 600px) {

  header {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding: 60px 0 30px 0;
   }
  
   h1 {
    font-size: 40px;
   }
  
  .inputForm {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
  }
  
  .inputForm input {
    width: 16rem;
    height: 2.9rem;
    padding: 1rem;
    border: none;
  }
  
  
  .todoList {
    width: 100vw;
    margin: 2rem auto;
  }
  
  .todo {
    margin: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .completed {
    text-decoration: line-through;
    opacity: 0.5;
  }
  
  
  .todoText {
    min-width: 12rem;
  }

}